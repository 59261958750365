import { useQuery } from '@tanstack/react-query'
import type { CryptoPriceData } from '../types/crypto'
import { fetchCryptoPrices } from '../data'
import { CryptoListSkeleton } from './CryptoListSkeleton'

export const CryptoList = () => {
  const { data, isLoading } = useQuery<CryptoPriceData>({
    queryKey: ['cryptoPrices'],
    queryFn: fetchCryptoPrices,
  })

  if (isLoading) return <CryptoListSkeleton />

  return (
    <div className="mt-4">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Cryptocurrency Prices</h3>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            {data &&
              Object.entries(data).map(([crypto, price]) => (
                <div key={crypto} className="px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500 capitalize">{crypto}</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">${price.usd}</dd>
                </div>
              ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
