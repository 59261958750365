import { eres } from './utils/eres'
import type { CryptoPriceData } from './types/crypto'

const API_BASE_URL = 'https://api.coingecko.com/api/v3'

export const fetchCryptoPrices = async (): Promise<CryptoPriceData> => {
  const url = new URL(`${API_BASE_URL}/simple/price`)
  url.searchParams.append('ids', 'bitcoin,ethereum')
  url.searchParams.append('vs_currencies', 'usd')

  // Fetch with eres
  const [fetchError, response] = await eres(fetch(url.toString()))
  if (fetchError || !response) {
    console.error('Error during fetch:', fetchError)
    throw new Error('Failed to fetch cryptocurrency prices.')
  }

  if (!response.ok) {
    throw new Error(`Failed to fetch cryptocurrency prices. Status: ${response.status}`)
  }

  // Parse JSON with eres
  const [jsonError, data] = await eres(response.json())
  if (jsonError || !data) {
    console.error('Error parsing JSON:', jsonError)
    throw new Error('Failed to parse cryptocurrency data.')
  }

  return data
}
