export const CryptoListSkeleton = () => {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="h-7 w-56 bg-gray-200 rounded animate-pulse mb-6" />
      <div className="space-y-4">
        {[...Array(5)].map((_, index) => (
          <div key={index} className="flex items-center justify-between py-4 border-b border-gray-100 last:border-b-0">
            <div className="h-6 w-32 bg-gray-200 rounded animate-pulse" />
            <div className="h-6 w-24 bg-gray-200 rounded animate-pulse" />
          </div>
        ))}
      </div>
    </div>
  )
}
