import { useAccount } from 'wagmi'
import { Dashboard } from './layouts/Dashboard'
import { CryptoList } from './components/CryptoList'

function App(): JSX.Element {
  const { isConnected } = useAccount()

  return (
    <Dashboard>
      <CryptoList />
    </Dashboard>
  )
}

export default App
